import React, { useEffect, useState } from 'react'
import {
  Link,
  Routes,
  Route,
  useLocation,
  Outlet,
  Navigate,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import localforage from 'localforage'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { motion, AnimatePresence } from 'framer-motion'
import { Field, SecureField, Awaiter } from '../components'


export default function Done(props) {
  const navigate = useNavigate()
  const { token, email, setEmail, chargeResult } = props
  const [loginError, setLoginError] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [isAwaiting, setIsAwaiting] = useState(false)

  useEffect(() => {
    if (token === '') {
      setLoginError('Token could not be empty!')
    } else {
      setLoginError('')
    }
  }, [token])

  const check = async event => {
    // app root reload \ rerender fix
    event.preventDefault()
    navigate('/done', { replace: true })

  }

  return (
    <>
      <Grid
        component='form'
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{
          height: '100vh',
          width: '50%',
          mx: '25%',
          px: 5,
          borderRadius: '10px',
          // border: '1px solid',
          borderColor: 'action.selected'
        }}
        onSubmit={check}
      >
        <Grid
          item
          container
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            // minHeight: '15vh'
          }}
        >
          <Typography variant='h4'>{'Result'}</Typography>

        </Grid>

        <Grid
          item
          container
          direction='column'
          alignItems='stretch'
          justifyContent='space-evenly'
          sx={{
            minHeight: '26vh'
          }}
        >

          <Typography align='center'>{'SUCCESS'}</Typography>
          <a href={chargeResult} target={'_blank'} align='center'>Receipt</a>

        </Grid>
        <Grid
          item
          container
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            minHeight: '25vh'
          }}
        >
          <Button
            fullWidth
            variant='outlined'
            size='large'
            onClick={() => {
              navigate('/subscription', { replace: true })
            }}
          >
            <Typography>{'Back'}</Typography>
          </Button>
        </Grid>
      </Grid>
      <Awaiter isAwaiting={isAwaiting} />
    </>
  );
}
