import React, { useEffect, useState } from 'react'
import {
  Link,
  Routes,
  Route,
  useLocation,
  Outlet,
  Navigate,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import localforage from 'localforage'
// Screens
import Login from './pages/login'
import Subscription from './pages/subscription'
import Charge from './pages/charge'
import Done from './pages/done'
// styles
import './App.css';

const ProtectedRoute = ({ isAllowed, redirectPath = '/login', children, }) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }
  return children ? children : <Outlet />;
}

export default function App() {
  const [token, setToken] = useState('')
  const [email, setEmail] = useState('')
  const [chargeResult, setChargeResult] = useState('')

  return (
    <Routes>
      <Route path='/login' element={<Login token={token} setToken={setToken} />} />

      <Route element={<ProtectedRoute
        isAllowed={!!token}
        redirectPath='/login'
      />}>
        <Route path='/subscription' element={<Subscription token={token} email={email} setEmail={setEmail} />} />
        <Route path='/charge' element={<Charge token={token} email={email} setEmail={setEmail} setChargeResult={setChargeResult} />} />
        <Route path='/done' element={<Done token={token} email={email} setEmail={setEmail} chargeResult={chargeResult} />} />
      </Route>

      <Route
        index
        path='*'
        element={<Navigate to='/login' replace={true} />}
      />
    </Routes>
  )
}
