import React, { useEffect, useState } from 'react'
import {
  Link,
  Routes,
  Route,
  useLocation,
  Outlet,
  Navigate,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import localforage from 'localforage'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { motion, AnimatePresence } from 'framer-motion'
import { Field, SecureField, Awaiter } from '../components'

const Paddle = window.Paddle
const emailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

export default function Sub(props) {
  const navigate = useNavigate()
  const { token, setToken, email, setEmail } = props
  // const [email, setEmail] = useState('')
  const [loginError, setLoginError] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [isAwaiting, setIsAwaiting] = useState(false)

  const [showPaddleLoader, setShowPaddleLoader] = useState(false)

  useEffect(() => {
    // Paddle.Environment.set('sandbox')
    console.log(Paddle.Status.libraryVersion)
    Paddle.Setup({
      vendor: 139630, // live - 139630, // sandbox - 4434,
      eventCallback: function (data) {
        // The data.event will specify the event type
        console.log(data.event, data.eventData)

        if (data.event === 'Checkout.Loaded') {
          // Paddle.Spinner.hide()
          setShowPaddleLoader(false)
        }
        if (data.event === 'Checkout.Complete') {
          // navigate('/processing')
        } else if (data.event === 'Checkout.Close') {
          setShowPaddleLoader(false)
          navigate('/charge', { replace: true })
        }
      }
    })
  }, [])

  useEffect(() => {
    if (email === '') {
      setLoginError('Email could not be empty!')
    } else if (!email.match(emailformat)) {
      setLoginError('Incorrect email address!')
    } else {
      setLoginError('')
    }
  }, [email])

  const check = async event => {
    // app root reload \ rerender fix
    event.preventDefault()
    console.log('Paddle productID', 802098)
    // Paddle.Spinner.show()
    setShowPaddleLoader(true)
    // navigate('/charge', { replace: true })
    Paddle.Checkout.open({
      product: 802098,
      email,
      passthrough: JSON.stringify({ action: 'charge', email })
    })
  }

  return (
    <>
      <Grid
        component='form'
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{
          height: '100vh',
          width: '50%',
          mx: '25%',
          px: 5,
          borderRadius: '10px',
          // border: '1px solid',
          borderColor: 'action.selected'
        }}
        onSubmit={check}
      >
        <Grid
          item
          container
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            minHeight: '15vh'
          }}
        >
          <Typography variant='h4'>{'Subscription'}</Typography>

        </Grid>

        <Grid
          item
          container
          direction='column'
          alignItems='stretch'
          justifyContent='space-evenly'
          sx={{
            minHeight: '26vh'
          }}
        >
          <Field
            value={email}
            setValue={setEmail}
            label={'Email'}
            placeholder={'john.doe@gmail.com'}
            errorMessage={loginError}
            showErrors={showErrors}
          />

        </Grid>
        <Grid
          item
          container
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            minHeight: '25vh'
          }}
        >
          <Button fullWidth variant='contained' size='large' type='submit'>
            <Typography>{'Subscribe'}</Typography>
          </Button>
          <Button
            fullWidth
            variant='outlined'
            size='large'
            onClick={() => {
              navigate('/charge', { replace: true })
            }}
          >
            <Typography>{'Charge'}</Typography>
          </Button>
        </Grid>
      </Grid>
      <Awaiter isAwaiting={isAwaiting} />
    </>
  );
}
