export async function login (token) {
  try {
    const response = await POST('https://api.dingovpn.com/charge/login', {
      token
    })
    console.log('login response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('login error', error)
    return { success: false }
  }
}

export async function charge (token, email, amount) {
  try {
    const response = await POST('https://api.dingovpn.com/charge/charge', {
      token,
      email,
      amount
    })
    console.log('login response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('login error', error)
    return { success: false }
  }
}


export async function POST (url, body) {
  const response = await fetch(url, {
    method: 'POST',
    // mode: 'no-cors', // no-cors, *cors, same-origin
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(body)
  })
  return response
}
