import React, { useEffect, useState } from 'react'
import {
  Link,
  Routes,
  Route,
  useLocation,
  Outlet,
  Navigate,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import localforage from 'localforage'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { motion, AnimatePresence } from 'framer-motion'
import { Field, SecureField, Awaiter } from '../components'
import { charge } from '../requests'

const Paddle = window.Paddle
const emailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

export default function Sub(props) {
  const navigate = useNavigate()
  const { token, setToken, email, setEmail, setChargeResult } = props
  // const [email, setEmail] = useState('')
  const [amount, setAmount] = useState('')
  const [loginError, setLoginError] = useState('')
  const [amountError, setAmountError] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [isAwaiting, setIsAwaiting] = useState(false)

  useEffect(() => {
    if (email === '') {
      setLoginError('Email could not be empty!')
    } else if (!email.match(emailformat)) {
      setLoginError('Incorrect email address!')
    } else {
      setLoginError('')
    }

    if (amount === '') {
      setAmountError('Amount could not be empty!')
    } else if (!email.match(emailformat)) {
      setAmountError('Incorrect amount!')
    } else {
      setAmountError('')
    }
  }, [email])

  const check = async event => {
    // app root reload \ rerender fix
    event.preventDefault()
    setIsAwaiting(true)
    const result = await charge(token, email, amount)
    setIsAwaiting(false)
    if (!result.success) {
      console.log('charge error', result)
      setShowErrors(true)
      setLoginError(result.error?.message || 'Unknown error')
    } else {
      setShowErrors(false)
      console.log('charge success', result)
      setChargeResult(result.response.receipt_url)
      navigate('/done', { replace: true })
    }
  }

  return (
    <>
      <Grid
        component='form'
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{
          height: '100vh',
          width: '50%',
          mx: '25%',
          px: 5,
          borderRadius: '10px',
          // border: '1px solid',
          borderColor: 'action.selected'
        }}
        onSubmit={check}
      >
        <Grid
          item
          container
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            minHeight: '15vh'
          }}
        >
          <Typography variant='h4'>{'Charge'}</Typography>

        </Grid>

        <Grid
          item
          container
          direction='column'
          alignItems='stretch'
          justifyContent='space-evenly'
          sx={{
            minHeight: '26vh'
          }}
        >
          <Field
            value={email}
            setValue={setEmail}
            label={'Email'}
            placeholder={'john.doe@gmail.com'}
            errorMessage={loginError}
            showErrors={showErrors}
          />

          <Field
            value={amount}
            setValue={setAmount}
            label={'Amount, USD'}
            placeholder={'5'}
            errorMessage={amountError}
            showErrors={showErrors}
          />

        </Grid>
        <Grid
          item
          container
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            minHeight: '25vh'
          }}
        >
          <Button fullWidth variant='contained' size='large' type='submit'>
            <Typography>{'Charge'}</Typography>
          </Button>
        </Grid>
      </Grid>
      <Awaiter isAwaiting={isAwaiting} />
    </>
  );
}
