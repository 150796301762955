import React, { useEffect, useState } from 'react'
import {
  Link,
  Routes,
  Route,
  useLocation,
  Outlet,
  Navigate,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import localforage from 'localforage'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { motion, AnimatePresence } from 'framer-motion'
import { Field, SecureField, Awaiter } from '../components'

import { login } from '../requests'

export default function Login(props) {
  const navigate = useNavigate()
  const { token, setToken } = props
  const [loginError, setLoginError] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [isAwaiting, setIsAwaiting] = useState(false)

  useEffect(() => {
    if (token === '') {
      setLoginError('Token could not be empty!')
    } else {
      setLoginError('')
    }
  }, [token])

  const check = async event => {
    // app root reload \ rerender fix
    event.preventDefault()
    if (loginError) {
      console.log('login or pass incorrect')
      setShowErrors(true)
    } else {
      setIsAwaiting(true)
      const result = await login(token)
      setIsAwaiting(false)
      if (!result.success) {
        console.log('login error', result)
        if (result.error) {
          setShowErrors(true)
          setLoginError(result.error || 'Unknown error')
        } else {
          setLoginError('')
        }
      } else {
        setShowErrors(false)
        console.log('login success', result)
        // trackEvent('Login')
        navigate('/subscription', { replace: true })
      }
    }
  }

  return (
    <>
      <Grid
        component='form'
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{
          height: '100vh',
          width: '50%',
          mx: '25%',
          px: 5,
          borderRadius: '10px',
          // border: '1px solid',
          borderColor: 'action.selected'
        }}
        onSubmit={check}
      >
        <Grid
          item
          container
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            // minHeight: '15vh'
          }}
        >
          <Typography variant='h4'>{'Auth'}</Typography>

        </Grid>

        <Grid
          item
          container
          direction='column'
          alignItems='stretch'
          justifyContent='space-evenly'
          sx={{
            minHeight: '26vh'
          }}
        >
          <Field
            value={token}
            setValue={setToken}
            label={'Access Token'}
            placeholder={'Access Token'}
            errorMessage={loginError}
            showErrors={showErrors}
          />

        </Grid>
        <Grid
          item
          container
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            minHeight: '25vh'
          }}
        >
          <Button fullWidth variant='contained' size='large' type='submit'>
            <Typography>{'Login'}</Typography>
          </Button>
        </Grid>
      </Grid>
      <Awaiter isAwaiting={isAwaiting} />
    </>
  );
}
