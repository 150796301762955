import React, { useEffect, useState, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'


import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
//
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import FilledInput from '@mui/material/FilledInput'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'


export function Field (params) {
  const {
    value,
    setValue,
    label,
    type,
    placeholder,
    errorMessage,
    showErrors
  } = params
  return (
    <TextField
      fullWidth
      variant='outlined'
      type={type}
      value={value}
      onChange={event => setValue(event.target.value)}
      error={showErrors && !!errorMessage}
      label={label || placeholder}
      placeholder={placeholder}
      helperText={showErrors && errorMessage}
    />
  )
}

export function SecureField (params) {
  const {
    value,
    setValue,
    label,
    placeholder,
    errorMessage,
    showErrors
  } = params

  const [showPassword, setShowPassword] = useState(false)
  return (
    <FormControl
      fullWidth
      sx={{}}
      variant='outlined'
      error={showErrors && !!errorMessage}
    >
      <InputLabel htmlFor='outlined-adornment-password'>{label}</InputLabel>
      <OutlinedInput
        id='outlined-adornment-password'
        type={showPassword ? 'text' : 'password'}
        value={value}
        label={label || placeholder}
        placeholder={placeholder}
        onChange={event => setValue(event.target.value)}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => {
                setShowPassword(!showPassword)
              }}
              onMouseDown={event => {
                event.preventDefault()
              }}
              edge='end'
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        aria-describedby='component-helper-text'
      />
      {showErrors ? (
        <FormHelperText id='component-helper-text'>
          {errorMessage}
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}

export const Awaiter = props => {
  const isAwaiting = props.isAwaiting

  if (!isAwaiting) return null

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={isAwaiting}
      onClick={() => {}}
    >
      <CircularProgress color='inherit' />
    </Backdrop>
  )
}
